import React, { useState } from "react";

const PullToRefresh = ({ children }) => {
  const [touchStartY, setTouchStartY] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isAtTop, setIsAtTop] = useState(false);

  const handleTouchStart = (e) => {
    // Проверяем, находится ли пользователь на самом верху контейнера
    if (window.scrollY === 0) {
      setIsAtTop(true);
      setTouchStartY(e.touches[0].clientY);
    } else {
      setIsAtTop(false);
    }
  };

  const handleTouchMove = (e) => {
    if (!isAtTop || touchStartY === null) return;

    const touchCurrentY = e.touches[0].clientY;
    const touchDeltaY = touchCurrentY - touchStartY;

    // Добавьте логику для визуальной обратной связи, если нужно
  };

  const handleTouchEnd = (e) => {
    if (!isAtTop || touchStartY === null) return;

    const touchEndY = e.changedTouches[0].clientY;
    const touchDeltaY = touchEndY - touchStartY;

    // Если пользователь потянул достаточно далеко вниз
    if (touchDeltaY > 700 && !isRefreshing) {
      setIsRefreshing(true);

      // Имитация обновления данных
      setTimeout(() => {
        window.location.reload(); // Перезагрузка страницы
        setIsRefreshing(false);
      }, 1000); // Время ожидания перед обновлением
    }

    setTouchStartY(null); // Сброс начальной точки касания
    setIsAtTop(false); // Сброс флага верхней позиции
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isRefreshing && <div style={{ textAlign: "center" }}>Обновляем...</div>}
      {children}
    </div>
  );
};

export { PullToRefresh };
