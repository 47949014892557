import '../css/Search.css'
import React, { useState, useEffect } from 'react';
import Dropdown from './DropDown';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const moment = require('moment');

const Search = props => {

    const navigate = useNavigate()

    const [filterData, setFilterData] = useState([
        { addBike: {
            id: 1, fio: 'React Hooks' }}
      ]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isKok, setIsKok] = useState(false)

    const nav = (id) => {
        navigate(`/redAdd${id}`)
        // console.log(props)
    }

    const onlySell = () => {
        props.onlySell()
    }
    const console = () => {
        props.console()
    }

    useEffect(() => {
        setFilterData(props.upSort)
        if(props) {
            setIsKok(props.isKok)
        }
        // console.log(props.upSort)
    },[props])

    const handleChangeName = (event) => {
        setSearchTerm(event.target.value);
      };
      
      const filteredArticles = filterData.filter((article) => {
        const fio = article.addBike.fio ? article.addBike.fio : '';
        const regNumber = article.addBike.regNumber ? article.addBike.regNumber : '';
        const phone = article.addBike.phone ? article.addBike.phone : '';
        const win = article.addBike.WIN ? article.addBike.WIN : '';
      
        return (
          fio.toLowerCase().includes(searchTerm.toLowerCase()) ||
          regNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
          win.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      



    return (
        <div className='codeCont'> 
        <div className='inpHeader'>
        </div>
        <div className="inpCont" >
            <input className='inputInp'
            placeholder="Name, phone number, number bike, WIN"
         type="text" value={searchTerm} onChange={handleChangeName}/>
         <a style={{textDecoration:"none"}} href="https://valueplusexchange.com/#/" target="_blank" rel="noreferrer"><div className="btnCourse">Exchange rates V+</div></a>
         {!isKok && <div className="searchInputCheck">
                <p style={{margin:"0px"}}>Проданные:</p>
                    <input 
                        className="searchCheck"
                        type='checkbox'
                        checked={props.sell}
                        onChange={onlySell}
                    />
                    
        </div>}
        {!isKok && <div className="searchInputCheck" style={{backgroundColor:"#cb9afc"}}>
                <p style={{margin:"0px"}}>Консоли:</p>
                    <input 
                        className="searchCheck"
                        type='checkbox'
                        checked={props.showConsole}
                        onChange={console}
                    />
                    
        </div>}
        {/* <img src={tableSet} alt="-" style={{width:"30px", height:"30px", marginLeft:"40px"}}/> */}
        <Dropdown chLocal={props.chLocal}/>
         </div>
        {searchTerm &&
        <table className='table' style={{width:"600px"}}>
            <thead >
                <tr >
                    <th>Model</th>
                    <th>Number</th>
                    <th>WIN</th>
                    <th>Name Customer</th>
                    <th>Date Finish Rent</th>
                    <th>Phone number</th>
                </tr>
        </thead>
        <tbody>
        {searchTerm && (filteredArticles.map((article) => (
            <tr key={article.id} onClick={()=> nav(article.addBike.id)} style={{cursor:"pointer"}} >
                <td>{article.addBike.model} ({article.addBike.bikeNumber})</td>
                <td>{article.addBike.regNumber}</td>
                <td>{article.addBike.WIN}</td>
                <td>{article.addBike.fio}</td>
                <td>{moment(article.addBike.dateEnd).format("DD-MM-YYYY HH:mm")}</td>
                {article.addBike.phone ? <td>{article.addBike.phone}</td> : <td>-</td>}
            </tr>
        )))}
        </tbody>
      </table>
}


    </div>
    )
}

export default Search