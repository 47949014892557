import React, { useState} from "react";
import choose from '../image/choose.png'

const DropModels = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All bikes");


  // Функция переключения выпадающего списка
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const clickModel = (model) => {
      props.filterModel(model)
      setIsOpen(false)
      setSelectedItem(model)
  }


  

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
        <div onClick={toggleDropdown} style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"left", backgroundColor:"#c7c7c7", width:"170px",padding:"5px", paddingLeft:"10px", paddingRight:"10px", borderRadius:"5px", boxShadow:"1px 1px 1px rgba(0,0,0,0.4)", marginBottom:"20px", cursor:"pointer"}}>
          <img src={choose} alt="-" style={{width:"25px", height:"25px"}}/>
          <p style={{margin:"0px", marginLeft:"15px"}}>Choose model</p>
          </div>

      {/* Выпадающий список */}
      {isOpen && (
        <ul
          style={{
            position: "absolute",
            width: "200px",
            top: "100%",
            left:"5%",
            margin: "0",
            padding: "10px",
            listStyle: "none",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          {props.listModels.map((item) => (
            <li
              key={item}
              style={{
                padding: "5px 10px",
                cursor: "pointer",
                borderBottom: "1px solid grey",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: item==="All bikes" ? "18px": "16px", 
                fontWeight:item === "All bikes" ? "bold":""
              }}
              onClick={() => clickModel(item)}
            >
              <span >{item}</span>
              {/* Галочка, если элемент выбран */}
              {item === selectedItem && <span>✔</span>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropModels;
